import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { ClrViewerComponent } from './components/clr-viewer/clr-viewer.component';
import {ClrBuilderComponent} from './components/clr-builder/clr-builder.component';
import {ClrShareComponent} from './components/clr-share/clr-share.component';
import { AuthGuardService } from './services/common/auth-guard.service';


// Routes
const appRoutes: Routes = [
 { path: '', pathMatch: 'full', redirectTo: '/clr-viewer' },
 { path: 'authenticate', component: AuthenticationComponent },
 { path: 'clr-viewer', component: ClrViewerComponent, canActivate: [AuthGuardService] },
 { path: 'clr-builder', component: ClrBuilderComponent},
 { path: ':profileUrl/:indicator/:accessToken', component: ClrShareComponent},
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes);
